<template>
  <homepage-section variant="white">
    <content-wrapper>
      <content-stack :spacing="32">
        <decorative-divider/>
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          :spacing="8">
          <content-wrapper
            :max-width="isMobile ? 400 : 540"
            class="basics-image">
            <homepage-image
              width="100%"
              height="auto"
              alt="Photo grid of Armoire member Kim wearing various Armoire garments outdoors, at dinner, and at her desk"
              webp-source="howitworks/jan2022/thebasics@2x.webp"
              source="howitworks/jan2022/thebasics.jpg"
              source-type="image/jpeg"/>
          </content-wrapper>
          <div class="basics-content">
            <content-wrapper :max-width="isMobile ? 320 : 400">
              <content-stack
                :spacing="isMobile ? 8 : 16"
                alignment="left">
                <homepage-text
                  tag="h2"
                  class="text-start"
                  styling-variant="heading1">
                  The Basics
                </homepage-text>
                <content-stack
                  :spacing="isMobile ? 10 : 12"
                  alignment="left">
                  <content-row
                    v-for="(benefit, idx) in bullets"
                    :key="`benefit-${idx}`"
                    class="benefit"
                    :spacing="10">
                    <svg-check
                      class="benefit-check"
                      variant="secondary"/>
                    <homepage-text
                      styling-variant="heading3"
                      class="text-start benefit-text">
                      {{ benefit }}
                    </homepage-text>
                  </content-row>
                </content-stack>
                <get-started-button
                  button-variant="primary"/>
              </content-stack>
            </content-wrapper>
          </div>
        </component>
        <decorative-divider variant="bottom"/>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import GetStartedButton from '@/homepage/components/common/GetStartedButton.vue'

import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import SvgCheck from '@/homepage/components/common/SvgCheck.vue'

export default {
  components: {
    ContentRow,
    ContentStack,
    ContentWrapper,
    HomepageImage,
    HomepageText,
    HomepageSection,
    MarketingButton,
    DecorativeDivider,
    SvgCheck,
    GetStartedButton
  },
  data () {
    return {
      bullets: [
        'Access to 75K+ high-end styles',
        'Members-only discounts to purchase items you love',
        'Free & fast shipping',
        'Full-service styling',
        'Cleaning is on us'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.basics-content, .basics-image {
  width: 100%;

  @include medium {
    width: 46%;
    flex-shrink: 0;
  }

}

.benefit {
  align-items: flex-start;

  .benefit-check {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-left: 0;
  }

  .benefit-text {
    margin-top: 3px;
  }
}

</style>
