<template>
  <marketing-button
    type="a"
    target="_self"
    :variant="buttonVariant"
    aria-label="Navigate to style quiz"
    :href="getStyleQuizUrl()">
    Get Started
  </marketing-button>
</template>
<script>
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

import MarketingButton from './MarketingButton.vue'

export default {
  name: 'GetStartedButton',
  components: {
    MarketingButton
  },
  props: {
    buttonVariant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary|tertiary)/)
      }
    }
  },
  setup () {
    const { getStyleQuizUrl } = useStyleQuizUrl()
    return {
      getStyleQuizUrl
    }
  }
}
</script>
