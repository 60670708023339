<template>
  <homepage-section variant="white">
    <content-stack>
      <content-wrapper>
        <content-stack>
          <content-wrapper :max-width="686">
            <homepage-text
              tag="h2"
              styling-variant="heading1">
              Armoire vs. Other clothing rental subscriptions
            </homepage-text>
          </content-wrapper>
          <div class="table-container">
            <table class="comparison-table">
              <thead>
                <tr>
                  <th>
                    <homepage-text
                      class="text-start"
                      styling-variant="heading2">
                      Features
                    </homepage-text>
                  </th>
                  <th class="company">
                    <homepage-text
                      class="company-header"
                      styling-variant="heading3">
                      Armoire
                    </homepage-text>
                  </th>
                  <th class="company">
                    <homepage-text
                      class="company-header"
                      styling-variant="heading3">
                      Rent the Runway
                    </homepage-text>
                  </th>
                  <th class="company">
                    <homepage-text
                      class="company-header"
                      styling-variant="heading3">
                      Nuuly
                    </homepage-text>
                  </th>
                </tr>
              </thead>
              <tbody class="comparison-body">
                <tr
                  v-for="attribute in attributes"
                  :key="attribute.id">
                  <td>
                    <homepage-text
                      class="text-start feature"
                      styling-variant="copy-small">
                      {{ attribute.text }}
                    </homepage-text>
                  </td>
                  <td>
                    <svg-check
                      v-if="attribute.armoire"
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"
                      :stroke-width="2.5"
                      variant="success"/>
                  </td>
                  <td>
                    <svg-check
                      v-if="attribute.rtr"
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"
                      :stroke-width="2.5"
                      variant="success"/>
                    <svg-x
                      v-else
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"/>
                  </td>
                  <td>
                    <svg-check
                      v-if="attribute.nuuly"
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"
                      :stroke-width="2.5"
                      variant="success"/>
                    <svg-x
                      v-else
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <homepage-get-started-lockup>
            Take your Style Quiz today and experience the best of clothing rental
          </homepage-get-started-lockup>
        </content-stack>
      </content-wrapper>
      <decorative-divider variant="bottom"/>
    </content-stack>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageGetStartedLockup from '@/homepage/components/common/HomepageGetStartedLockup.vue'
import SvgCheck from '@/homepage/components/common/SvgCheck.vue'
import SvgX from '@/homepage/components/common/SvgX.vue'

export default {
  components: {
    ContentStack,
    ContentWrapper,
    DecorativeDivider,
    HomepageText,
    HomepageSection,
    HomepageGetStartedLockup,
    SvgCheck,
    SvgX
  },
  data () {
    return {
      attributes: [
        {
          id: 'curated',
          text: 'Curated closet via recommendation algorithm and personal stylists',
          armoire: true,
          rtr: false,
          nuuly: false
        },
        {
          id: 'flexible',
          text: 'Flexible plans for every budget, including an Unlimited plan',
          armoire: true,
          rtr: false,
          nuuly: false
        },
        {
          id: 'high-end',
          text: 'High-end brands you love',
          armoire: true,
          rtr: true,
          nuuly: true
        },
        {
          id: 'women',
          text: 'Rising women- and POC-owned brands you should know about',
          armoire: true,
          rtr: false,
          nuuly: false
        },
        {
          id: 'returns',
          text: 'Flexible returns — keep your old items until new items arrive 😮',
          armoire: true,
          rtr: false,
          nuuly: false
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.table-container {
  border-top: .5px solid $velvet;
  padding-top: 24px;
}

.feature {
  min-width: 128px;
}

.comparison-table {
  border-collapse: collapse;

  th {
    padding: 4px;

    @include medium {
      padding: 12px;
    }

    &.company {
      padding: 8px 3px;

      @include medium {
        width: 16%;
        padding: 16px 0;
      }

      .company-header {
        font-size: $font-size-base * .6;
        line-height: $font-size-base * .8;

        @include medium {
          font-size: unset;
          line-height: unset;
        }
      }
    }
  }

  .comparison-body {
    tr:nth-child(even) {background: $white}
    tr:nth-child(odd) {background: $origami}

    td {
      max-width: 560px;
      vertical-align: middle;
      text-align: center;
      padding: 16px 9px;

      @include medium {
        padding: 16px 20px;
      }

    }
  }
}

.svg {
  margin-top: 4px;
}

</style>
