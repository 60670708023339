<template>
  <div>
    <referral-banner
      :client="client"
      :referral-campaign="referralCampaign"/>
    <renting-with-armoire/>
    <div class="hr-wrapper">
      <hr class="referral-hr">
    </div>
    <closet-preview/>
    <plans-section
      :referrer="client"
      :referral-campaign="referralCampaign"
      :referral-code="$route.params.referralCode"/>
    <how-armoire-works/>
    <referral-footer
      :client="client"
      :referral-campaign="referralCampaign"/>
  </div>
</template>

<script>
import ClosetPreview from '@/homepage/components/common/ClosetPreview'
import ReferralBanner from './ReferralBanner'
import ReferralFooter from './ReferralFooter'
import RentingWithArmoire from './RentingWithArmoire'
import HowArmoireWorks from './HowArmoireWorks'
import PlansSection from '@/homepage/components/common/PlansSection.vue'
import { buildUrl } from '@/homepage/utils/url'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  name: 'ReferralHome',
  components: {
    HowArmoireWorks,
    RentingWithArmoire,
    ReferralFooter,
    ReferralBanner,
    ClosetPreview,
    PlansSection
  },
  setup () {
    const { trackPageView } = useAnalytics()
    return { trackPageView }
  },
  data () {
    return {
      client: null,
      referralCampaign: null
    }
  },
  created () {
    // Only include in prod; can cause CORS issues during dev
    const headers = process.env.NODE_ENV === 'production'
      ? { 'APP-BUILD-TIME': process.env.VUE_APP_BUILD_TIME }
      : {}

    fetch(buildUrl('/api/referralcampaigns/'), { headers })
      .then(res => res.json())
      .then(res => { this.referralCampaign = res[0] })
    fetch(buildUrl(`/api/clients/?referralCode=${this.$route.params.referralCode}`), { headers })
      .then(res => {
        if (res.status !== 200) {
          window.location.replace('/')
        } else {
          return res.json()
        }
      })
      .then(res => {
        this.client = res
      })
  },
  activated () {
    this.trackPageView('Referral Landing Page')
  }
}
</script>

<style lang="scss" scoped>

.hr-wrapper {
  padding: 12px 24px 0;
}

.referral-hr {
  border: 1px solid $origami;
  max-width: 1200px;
  margin: auto;
}
</style>
