/* eslint-disable */
import Vue from 'vue'

const VueIntercom = {
  install(Vue, options) {
    var APP_ID = "kt8uadvo";
    (function(){
      var w=window;
      var ic=w.Intercom;
      if (typeof ic==="function") {
        ic('reattach_activator');
        ic('update',w.intercomSettings);
      }
      else {
        var d=document;
        var i=function() {
          i.c(arguments);
        };
        i.q=[];
        i.c=function(args ){
          i.q.push(args);
        };
        w.Intercom=i;
        var l=function() {
          setTimeout(() => {
            var s=d.createElement('script');
            s.type='text/javascript';
            s.async=true;
            s.src='https://widget.intercom.io/widget/' + APP_ID;
            var x=d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s,x);
          }, 4000);
        };
        if(w.attachEvent){
          w.attachEvent('onload',l);
        } else{
          w.addEventListener('load',l,false);
        }
      }
    }
    )();

    setTimeout(() => {
      window.Intercom('boot', {
        app_id: 'kt8uadvo',
      });
    }, 1000);
  }
}

export default VueIntercom