<template>
  <homepage-section>
    <content-wrapper>
      <content-stack class="plans-content">
        <content-wrapper :max-width="686">
          <homepage-text
            tag="h2"
            class="text-center text-white"
            styling-variant="heading1">
            Plans starting from {{ plans.capsule.basePrice }}
          </homepage-text>
        </content-wrapper>
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          class="benefits">
          <content-stack
            v-for="benefit in benefits"
            :key="benefit"
            :spacing="8"
            class="benefit">
            <svg-check
              :size="80"
              :stroke-width=".5"
              class="plan-check"
              variant="secondary"/>
            <homepage-text
              tag="p"
              class="text-white benefit-text"
              styling-variant="heading2">
              {{ benefit }}
            </homepage-text>
          </content-stack>
        </component>
        <component :is="isMobile ? 'content-stack' : 'content-row'">
          <marketing-button
            type="a"
            target="_self"
            :href="variant === 'explore-plans' ? '/howitworks/#plans' : getStyleQuizUrl()"
            variant="secondary">
            {{ variant === 'explore-plans' ? 'Explore Our Plans' : 'Get Started' }}
          </marketing-button>
        </component>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import SvgCheck from '@/homepage/components/common/SvgCheck.vue'

import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

export default {
  components: {
    ContentWrapper,
    ContentRow,
    HomepageSection,
    HomepageText,
    ContentStack,
    MarketingButton,
    SvgCheck
  },
  props: {
    variant: {
      type: String,
      default: 'explore-plans'
    }
  },
  setup () {
    const { getStyleQuizUrl } = useStyleQuizUrl()
    return {
      getStyleQuizUrl
    }
  },
  data () {
    return {
      plans: window.armoire.plans,
      benefits: [
        'Free Cleaning',
        'Free Shipping',
        'Free Styling'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.plans-content, .benefits {
  width: 100%;
}

.benefit {
  width: 100%;
  border-top: $default-border;

  @include medium {
    width: 30%;
  }
}

.benefit-text {
  @include medium {
    max-width: 160px;
  }

  @include extralarge {
    max-width: unset;
  }
}

.plan-check {
  margin-top: 20px !important; // !important to override ContentStack margin.
  border: 2px solid $white;
  border-radius: 50%;
  padding: 4px;
}
</style>
