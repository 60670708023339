<template>
  <homepage-section variant="white">
    <content-stack
      :spacing="40">
      <decorative-divider/>
      <content-wrapper class="stylish-section">
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          class="column-reverse justify-content-between"
          :spacing="isMobile ? 24 : 0">
          <content-wrapper
            class="stylish-wrapper"
            :max-width="440">
            <content-stack
              :spacing="24"
              alignment="left">
              <content-stack :spacing="8">
                <homepage-text
                  tag="h2"
                  class="text-start"
                  styling-variant="heading1">
                  Keeping you stylish + safe
                </homepage-text>
                <homepage-text class="text-start">
                  At Armoire, we have tight processes to ensure all clothing is cleaned and sanitized prior to shipping. We use both wet and dry methods for cleaning, and utilize a steam process which heats to 250 degrees. (According to the CDC, flu viruses are killed by heating to 167 degrees or higher).
                </homepage-text>
              </content-stack>
              <get-started-button/>
            </content-stack>
          </content-wrapper>
          <homepage-image
            class="stylish-safe"
            width="100%"
            height="auto"
            alt="Armoire employee holding a dress in the Armoire warehouse"
            webp-source="howitworks/jan2022/stylishsafe.webp"
            source="howitworks/jan2022/stylishsafe.jpg"
            source-type="image/jpeg"/>
        </component>
      </content-wrapper>
    </content-stack>
    <content-stack
      :spacing="16">
      <content-wrapper class="stylish-section stylist">
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          class="justify-content-between"
          :spacing="isMobile ? 24 : 0">
          <div class="stylist-switcher-container">
            <plain-button
              class="switch-button left"
              @click="updateIndex(-1)">
              <svg-chevron-left/>
            </plain-button>
            <plain-button
              class="switch-button right"
              @click="updateIndex(1)">
              <svg-chevron-right/>
            </plain-button>
            <transition
              name="fade"
              mode="out-in">
              <content-stack
                :key="selectedMxTeamMember.id"
                :spacing="6">
                <homepage-text styling-variant="heading3">
                  {{ selectedMxTeamMember.name }}
                </homepage-text>
                <div class="mx-image-container">
                  <homepage-image
                    v-if="inViewport.now"
                    width="100%"
                    height="auto"
                    :alt="`Profile picture of ${selectedMxTeamMember.name}`"
                    :webp-source="`howitworks/jan2022/stylebestie/${selectedMxTeamMember.id}.webp`"
                    :source="`howitworks/jan2022/stylebestie/${selectedMxTeamMember.id}.png`"
                    source-type="image/png"/>
                </div>
                <homepage-text
                  class="text-armor"
                  styling-variant="quote-small">
                  “{{ selectedMxTeamMember.quote }}”
                </homepage-text>
              </content-stack>
            </transition>
          </div>
          <content-wrapper
            class="stylish-wrapper"
            :max-width="440">
            <content-stack
              :spacing="24"
              alignment="left">
              <content-stack :spacing="8">
                <homepage-text
                  tag="h2"
                  class="text-start"
                  styling-variant="heading1">
                  Meet your new style bestie
                </homepage-text>
                <homepage-text class="text-start">
                  Request help from the experts at your convenience! Ask a stylist to pull items for a special occasion or to fit a unique need by using the chat feature on our website or by emailing hi@armoire.style. Short on time? Stylists can also select items for you with the click of a button!
                </homepage-text>
              </content-stack>
              <get-started-button/>
            </content-stack>
          </content-wrapper>
        </component>
      </content-wrapper>
    </content-stack>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import inViewport from 'vue-in-viewport-mixin'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'
import GetStartedButton from '@/homepage/components/common/GetStartedButton.vue'

export default {
  components: {
    ContentStack,
    ContentWrapper,
    HomepageText,
    HomepageSection,
    DecorativeDivider,
    MarketingButton,
    ContentRow,
    HomepageImage,
    SvgChevronLeft,
    SvgChevronRight,
    PlainButton,
    GetStartedButton
  },
  mixins: [inViewport],
  data () {
    return {
      mxTeamIndex: 0,
      mxTeamMembers: [
        {
          id: 'kaylee',
          name: 'Kaylee',
          quote: 'Styling has been a passion of mine for as long as I can remember. Everyone is unique, and I love helping people express themselves through the way they dress'
        },
        {
          id: 'jayla',
          name: 'Jayla',
          quote: 'Style is a form of self expression. Let\'s elevate your current wardrobe with key pieces to help you look and feel your best with confidence!'
        },
        {
          id: 'nicolette',
          name: 'Nicolette',
          quote: 'Whether you have special events to attend or you\'re working from home, feeling good in what you wear is important. Let\'s get these outfits started!'
        }
      ]
    }
  },
  computed: {
    selectedMxTeamMember () {
      return this.mxTeamMembers[this.mxTeamIndex]
    }
  },
  methods: {
    updateIndex (increment) {
      this.mxTeamIndex = this.mxTeamIndex + increment
      if (this.mxTeamIndex === -1) this.mxTeamIndex = this.mxTeamMembers.length - 1
      if (this.mxTeamIndex === this.mxTeamMembers.length) this.mxTeamIndex = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.stylish-section {
  border-top: .5px solid $velvet;
  padding: 24px 0 0;
  margin-bottom: 0 !important; // !important to override ContentStack margin.
}

.stylist {
  border-bottom: .5px solid $velvet;
  padding-bottom: 24px;
}

.stylish-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.stylish-safe, .stylist-switcher-container {
  width: 100%;

  @include medium {
    width: 50%;
  }

  margin: 0;
  flex-shrink: 0;
}

.stylish-safe {
  @include medium {
    margin-left: 24px;
  }

  @include large {
    margin-left: 0;
  }
}

.stylist-switcher-container {
  position: relative;
  background: $origami;
  color: $pewter;
  padding: 24px;
  max-width: 480px;

  @include medium {
    max-width: unset;
    margin-right: 24px;
  }

  @include large {
    margin-right: 0;
  }
}

.switch-button {
  position: absolute;
  top: 50%;
  margin-top: -5%;
  z-index: 2;

  &.left {
    left: 16px;
  }

  &.right {
    right: 16px;
  }
}

.mx-image-container {
  width: 70%;
  height: 68%;
  aspect-ratio: .85;

  @include medium {

  }

  @include large {
    min-height: 340px;
  }
}

</style>
