<template>
  <homepage-section
    class="faq"
    variant="white">
    <content-wrapper>
      <content-stack :spacing="24">
        <decorative-divider/>
        <content-wrapper :max-width="686">
          <homepage-text
            tag="h2"
            styling-variant="heading1">
            Frequently Asked Questions
          </homepage-text>
          <div class="switcher-container">
            <faq-switcher/>
          </div>
        </content-wrapper>
        <decorative-divider variant="bottom"/>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script setup>
import FaqSwitcher from './FaqSwitcher.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { onActivated } from 'vue'

const { trackPageView } = useAnalytics()
onActivated(() => {
  trackPageView('FAQ Page')
})

</script>

<style lang="scss" scoped>
.faq {
  margin-top: 60px;
}

.switcher-container {
  margin-top: 36px;
  padding: 12px 0;
  border-top: $default-border;
}
</style>
