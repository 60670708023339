<template>
  <homepage-section>
    <content-wrapper>
      <content-stack :spacing="24">
        <homepage-text
          class="text-white"
          tag="h2"
          styling-variant="heading1">
          Membership Perks
        </homepage-text>
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          :spacing="isMobile ? 4 : 12"
          class="flex-wrap">
          <component
            :is="isMobile ? 'content-row' : 'content-stack'"
            v-for="(perk, idx) in perks"
            :key="`perk-${idx}`"
            class="perk"
            :spacing="12">
            <homepage-image
              v-if="inViewport.now"
              width="100%"
              height="auto"
              class="perk-image"
              :alt="`Decorative illustration of ${perk.title} ${perk.title2}`"
              :webp-source="`howitworks/jan2022/membershipperks/${idx + 1}.webp`"
              :source="`howitworks/jan2022/membershipperks/${idx + 1}.png`"
              source-type="image.png"/>
            <content-stack
              :spacing="4"
              :alignment="isMobile ? 'left' : 'center'">
              <homepage-text
                class="text-white headline"
                :class="isMobile ? 'text-start' : 'text-center'"
                styling-variant="heading3">
                {{ perk.title }}<span v-if="perk.title2"><br v-if="!isMobile"> {{ perk.title2 }}</span>
              </homepage-text>
              <homepage-text
                class="text-white"
                :class="isMobile ? 'text-start' : 'text-center'"
                styling-variant="copy-small">
                {{ perk.subtitle }}
              </homepage-text>
            </content-stack>
          </component>
        </component>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'

import inViewport from 'vue-in-viewport-mixin'

export default {
  components: {
    ContentStack,
    ContentWrapper,
    HomepageImage,
    HomepageSection,
    HomepageText,
    ContentRow
  },
  mixins: [inViewport],
  data () {
    return {
      perks: [
        {
          title: 'Thousands of',
          title2: 'high-end styles',
          subtitle: '75K+ high-end items from hundreds of top designers'
        },
        {
          title: 'Buy what you love',
          title2: '(at a discount)',
          subtitle: 'Purchase your favorite items at members-only prices'
        },
        {
          title: 'Full-service',
          title2: 'styling',
          subtitle: 'Our stylists can curate a closet or case just for you'
        },
        {
          title: 'Free & fast shipping,',
          title2: 'both ways',
          subtitle: 'Reusable bag & prepaid shipping label are included for easy returns'
        },
        {
          title: 'Cleaning is',
          title2: 'on us',
          subtitle: 'Don\'t worry about laundry or dry cleaning, it\'s included!'
        },
        {
          title: 'Never be without',
          title2: 'clothes',
          subtitle: 'Keep your current case at home until your new items arrive'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.perk-image {
  max-width: 60px;
  max-height: 60px;
  flex-shrink: 0;
  flex-grow: 0;

  @include medium {
    max-width: 80px;
    max-height: 80px;
  }
}

.perk {
  width: 100%;
  max-width: 480px;
  border-top: $default-border;
  padding: 20px 0 16px;
  flex-grow: 0;
  justify-content: flex-start;

  @include medium {
    width: 31%;
    max-width: unset;
    justify-content: flex-start;
    flex-grow: 1;
    min-height: 272px;
  }
}
</style>
