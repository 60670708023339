<template>
  <homepage-section>
    <content-wrapper>
      <content-stack>
        <content-wrapper :max-width="686">
          <homepage-text
            tag="h2"
            class="text-center text-white"
            styling-variant="heading1">
            For women, By women
          </homepage-text>
        </content-wrapper>
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          class="border-top border-bottom ambika-quote"
          :spacing="isMobile ? 0 : 32">
          <content-stack>
            <homepage-image
              v-if="inViewport.now"
              class="ambika"
              height="100%"
              width="100%"
              alt="Ambika Singh smiling outside"
              webp-source="forbywomen/ambika.webp"
              source="forbywomen/ambika.jpg"
              source-type="image/jpeg"/>
            <content-stack :spacing="0">
              <homepage-text
                tag="p"
                class="text-white"
                styling-variant="heading3">
                Ambika Singh
              </homepage-text>
              <homepage-text
                class="normal text-white"
                tag="p"
                styling-variant="heading3">
                Founder + CEO
              </homepage-text>
            </content-stack>
          </content-stack>
          <content-wrapper :max-width="512">
            <content-stack :spacing="4">
              <svg-decorative-quote/>
              <homepage-text
                tag="p"
                class="text-white"
                styling-variant="quote-small">
                “I've been sharing clothes with the women in my life since as long as I can remember. <strong>As a female powered company</strong> ourselves, we’re committed to <strong>supporting other women.</strong> Armoire carries a <strong>growing number of female founded brands</strong>, started by creative, driven women just like you.”
              </homepage-text>
            </content-stack>
          </content-wrapper>
        </component>
        <content-stack :spacing="12">
          <content-wrapper
            :max-width="686">
            <homepage-text
              tag="h3"
              class="text-white"
              styling-variant="heading3">
              We love connecting our members to their new favorite female-owned brands
            </homepage-text>
          </content-wrapper>
          <content-row
            v-if="inViewport.now"
            :spacing="4"
            class="flex-wrap">
            <plain-button
              v-for="brand in womenOwnedBrands"
              :key="brand.id"
              type="a"
              class="women-owned-brand"
              :href="`/closet/brand/${brand.pageLink}`"
              :label="`Link to brand page for ${brand.name}`">
              <homepage-image
                v-if="inViewport.now"
                class="brand-member"
                width="100%"
                height="auto"
                :alt="`Link to brand page for ${brand.name}`"
                :webp-source="`forbywomen/members/${brand.id}.webp`"
                :source="`forbywomen/members/${brand.id}.jpg`"
                source-type="image/jpeg"/>
              <homepage-image
                v-if="inViewport.now"
                width="100%"
                height="auto"
                alt=""
                class="brand-logo"
                :webp-source="`forbywomen/logos/${brand.id}.webp`"
                :source="`forbywomen/logos/${brand.id}.png`"
                source-type="image/png"/>
            </plain-button>
          </content-row>
        </content-stack>
        <component
          :is="isMobile ? 'text-link' : 'marketing-button'"
          :variant="isMobile ? 'secondary': 'tertiary'"
          type="a"
          href="/closet/u/Women_Owned_Brands/wxdjrn2jpY">
          Explore women-owned brands we love
        </component>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import SvgDecorativeQuote from './SvgDecorativeQuote.vue'
import TextLink from '@/homepage/components/common/HomepageTextLink.vue'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import inViewport from 'vue-in-viewport-mixin'

export default {
  components: {
    ContentWrapper,
    ContentRow,
    HomepageImage,
    HomepageSection,
    HomepageText,
    ContentStack,
    MarketingButton,
    SvgDecorativeQuote,
    PlainButton,
    DecorativeDivider,
    TextLink
  },
  mixins: [inViewport],
  data () {
    return {
      womenOwnedBrands: [
        {
          name: 'Zuri',
          pageLink: 'zuri/rGbVMrbAPD',
          id: 'zuri'
        },
        {
          name: 'Paige',
          pageLink: 'paige/a9yVb2eVmw',
          id: 'paige'
        },
        {
          name: 'The Oula Company',
          pageLink: 'oula/GB1jQyvjP2',
          id: 'oula'
        },
        {
          name: 'Gravitas',
          pageLink: 'gravitas/076NaXvAXL',
          id: 'gravitas'
        },
        {
          name: 'Of Mercer',
          pageLink: 'of-mercer/9nrj21pN5w',
          id: 'of_mercer'
        },
        {
          name: 'Prima Dona',
          pageLink: 'prima-dona/vP0j6YyN4E',
          id: 'prima_dona'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.ambika-quote {
  padding: 0 0 20px;

  @include medium {
    padding: 24px 0;
  }

  .ambika {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.women-owned-brand {
  width: 45%;
  position: relative;
  background-color: $plum;
  margin-bottom: 2px;

  @include medium {
    width: 30%;
  }

  .brand-member {
    opacity: .85;
    @include transition-base-all;
  }

  .brand-logo {
    position: absolute;
    top: 50%;
    margin-top: -12%;
    z-index: 2;
    left: 15%;
    right: 15%;
    background: radial-gradient(50% 50% at 50% 50%, rgba($armor, .5) 31.3%, rgba($armor, 0) 100%);
  }

  &:hover {
    .brand-member {
      opacity: .2;
    }
  }
}

.divider {
  margin-right: 12px;
}
</style>
