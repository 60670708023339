<template>
  <div
    v-if="!isMobile"
    class="faq-switcher">
    <div class="faq-switcher-categories">
      <button
        v-for="category in categoryKeys"
        :key="category"
        class="faq-category"
        :class="{ 'selected': selectedCategorySwitcher === category }"
        @click="setSelectedCategorySwitcher(category)">
        {{ categoryData[category].title }}
      </button>
    </div>
    <transition
      mode="out-in"
      :name="`slide-${transitionDirection}`">
      <div
        :key="selectedCategorySwitcher"
        class="faq-switcher-content">
        <div
          v-for="(qAndA, index) in selectedCategorySwitcherData"
          :key="index"
          class="faq">
          <p class="question">
            {{ qAndA.question }}
          </p>
          <p
            class="answer"
            v-html="qAndA.answer"/>
        </div>
      </div>
    </transition>
  </div>
  <div
    v-else
    class="faq-dropdowns">
    <div class="faq-dropdown-categories">
      <div
        v-for="category in categoryKeys"
        :key="category">
        <button
          :id="category"
          class="faq-dropdown-button"
          :class="{ 'open': openCategoryDropdown === category }"
          @click="setOpenCategoryDropdown(category)">
          {{ categoryData[category].title }}
          <div class="icon-container">
            <svg-chevron-down
              class="chevron"
              :class="{ 'up': openCategoryDropdown === category }"/>
          </div>
        </button>
        <div
          class="faq-dropdown-content"
          :class="{ 'visible': openCategoryDropdown === category }">
          <div
            v-for="(qAndA, index) in categoryData[category].data"
            :key="index"
            class="faq">
            <p class="question">
              {{ qAndA.question }}
            </p>
            <p
              class="answer"
              v-html="qAndA.answer"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgChevronDown from '@/homepage/components/common/SvgChevronDown.vue'

export default {
  components: {
    SvgChevronDown
  },
  data () {
    return {
      prevCategorySwitcher: 'basics',
      selectedCategorySwitcher: 'basics',
      openCategoryDropdown: 'basics',
      categoryKeys: [
        'basics',
        'exchanges',
        'style',
        'cleaning',
        'billing'
      ],
      categoryData: {
        basics: {
          title: 'The Basics',
          data: [
            {
              question: 'How much does Armoire cost?',
              answer: `We offer flexible plans to fit a variety of budgets. Capsule memberships range from ${window.armoire.plans.capsule.basePrice}-${window.armoire.plans['capsule-7'].basePrice} a month, and Unlimited memberships are ${window.armoire.plans.unlimited.basePrice} a month.`
            },
            {
              question: 'Do I get to pick the styles I receive?',
              answer: 'Yes! You select the items you would like shipped to you. We curate a selection personalized to you with a combination of machine learning and input from our professional stylists — but you always choose exactly what you rent.'
            },
            {
              question: 'How many cases do I get each month?',
              answer: 'It’s up to you! With our unlimited plan, you’re welcome to exchange items every week if you like. Capsule plan members are eligible for a new case every 30 days. Unused shipments always roll over to the next month on our Capsule plans.'
            },
            {
              question: 'How many items come in my case?',
              answer: "Depending on your plan, we start you off with 4, 6, or 7 items. You're able to upgrade to larger cases at any point."
            },
            {
              question: 'What if I damage or lose an item?',
              answer: "Regular wear and tear is included in your monthly subscription. Lost items and theft aren't included. In those cases, you'll be charged 100% of the member price."
            },
            {
              question: 'What if I need a break or want to cancel?',
              answer: 'There is no commitment with Armoire. You can change, pause or cancel your membership at any time.'
            }
          ]
        },
        exchanges: {
          title: 'Exchanges',
          data: [
            {
              question: 'How often can I exchange?',
              answer: 'With our unlimited plan, you’re welcome to exchange items every week if you like. Capsule plan members are eligible for a new case every 30 days. Unused shipments always roll over to the next month on our Capsule plans.'
            },
            {
              question: 'How does exchanging work?',
              answer: 'You can exchange one item at a time, or all of your items -- it’s up to you. When you’re ready for something new, you notify us of which items you’ll be returning. You can select your new styles right away, and we’ll ship them immediately.'
            },
            {
              question: 'What do I ship my returns in?',
              answer: 'Your Armoire shipping bag is reusable! Simply attach the included pre-paid return label for shipping your exchanges.'
            },
            {
              question: 'How long can I keep my items?',
              answer: 'As long as you like! As long as you’re a subscribed customer, you can keep them for as long as you want.'
            },
            {
              question: 'Do I get to select new items right away?',
              answer: 'Yes! As soon as you notify us of your return, you can select your new items to rent and we’ll ship them out immediately. We do ask that you drop your returns items in the mail within 24 hours.'
            }
          ]
        },
        style: {
          title: 'Style + Fit',
          data: [
            {
              question: 'What types of clothing can I rent?',
              answer: 'From blouses to jumpsuits, dresses to denim, we have you covered. We carry a variety of styles ranging from weekend casual to black tie formal. The items in our inventory are hand selected from the top designers you recognize, as well as the independent designers we love. All our clothes are completely clean and feel like new.'
            },
            {
              question: 'How do I know the clothes will fit?',
              answer: 'Based on the information you provide on your Style Profile, our stylist selects a closet of items for you that will work for your body/preferences. As you rent more items and give feedback, the automated component of the curation process will select like brands, sizes and silhouettes that will work best for you! You can also use our size charts and reviews from other members to help you find your perfect fit.'
            },
            {
              question: 'What sizes do you carry?',
              answer: "We selectedly stock sizes 0R – 24W and maternity.<br> <br> Note: Even if you usually wear petites, we're often able to successfully dress petite women with our size range."
            },
            {
              question: 'Will I get access to a personal stylist?',
              answer: 'Yes! Enjoy a complimentary 1-1 stylist appointment each month, held in person or over the phone. You can also message a stylist at any time to get help with your closet.'
            },
            {
              question: 'What if I receive an item that doesn’t fit?',
              answer: 'When reviewing items, make sure to leave accurate fit feedback-- this helps our algorithm learn your shape and recommend brands and silhouettes to fit your body. Unlimited members can send back items that don’t fit for new options immediately.'
            }
          ]
        },
        billing: {
          title: 'Billing + Shipping',
          data: [
            {
              question: 'How does trial pricing work?',
              answer: `Try your first month of Armoire at special introductory pricing on these plans:<br><br>${window.armoire.plans.unlimited.name}: <strike>${window.armoire.plans.unlimited.basePrice}</strike> ${window.armoire.plans.unlimited.promoPrice} first month<br>${window.armoire.plans['capsule-7'].name}: <strike>${window.armoire.plans['capsule-7'].basePrice}</strike> ${window.armoire.plans['capsule-7'].promoPrice} first month<br><br>After your trial month, normal pricing automatically resumes. We’ll also send you a 20% off coupon to purchase any item in your closet when you continue after your trial month.`
            },
            {
              question: 'When will my card be charged?',
              answer: 'Your card on file will be charged on the day and time of your initial subscription. This is a recurring monthly subscription charge. For example, if you subscribed on the 1st of the month, your card will automatically be charged on the 1st of every month going forward. Your billing date is the day we process your payment each month.'
            },
            {
              question: 'Will I save money if I refer a friend?',
              answer: 'For each friend you sign up, you’ll receive $100 credit towards your Armoire subscription. The more friends who sign up, the more money back you get. Referred friends get perks too: they’ll receive 50% off their first month’s subscription.'
            },
            {
              question: 'Can I prepay for more than one month?',
              answer: 'Yes! Members who prepay save up to $193 annually. To switch your membership plan, email hi@armoire.style.'
            },
            {
              question: 'Can I give Armoire as a gift?',
              answer: 'Yes! Gift card options are available <a style="text-decoration: underline" href="https://support.armoire.style/gifts" target="_blank">here.</a>'
            },
            {
              question: 'When will my order arrive?',
              answer: 'Packages shipped from our warehouse will typically arrive in 2-3 business days. We’re taking extra precaution due to COVID-19, which means there may be slight delays in the receipt of your package or processing of your return.'
            },
            {
              question: 'Do you need to receive my return before sending me a new case?',
              answer: 'No. As soon as you notify us of your return, you can select your new items to rent and we’ll ship them out immediately. We do ask that you drop your returns items in the mail within 24 hours.'
            },
            {
              question: 'Do you ship internationally?',
              answer: 'No, at this time we only ship within the US.'
            }
          ]
        },
        cleaning: {
          title: 'Cleaning',
          data: [
            {
              question: 'How do you clean the clothing?',
              answer: 'We have tight processes to ensure all clothing is clean and sanitized prior to shipping. We use both wet and dry methods for cleaning, and utilize a steam process which heats to 250 degrees. Clothes are delivered to you professionally cleaned and ready to wear.'
            },
            {
              question: 'Are you taking any special precautions against COVID-19?',
              answer: 'Our team is taking all necessary steps to ensure best practices from the CDC and World Health Organization are being followed.<br><br>Although the CDC reports list shipped packages as very low probability transmission, we’ve doubled down on the existing very high standards around quality control. We have tight processes to ensure all clothing is clean and sanitized prior to shipping. We use both wet and dry methods for cleaning, and utilize a steam process which heats to 250 degrees. (According to the CDC, flu viruses are killed by heating to 167 degrees or higher.)<br><br>Packages are created in workstations that are regularly cleaned and disinfected, and team members are equipped with gloves and masks, conduct regular hand washing and temperature checks.'
            }
          ]
        }
      }
    }
  },
  computed: {
    selectedCategorySwitcherData () {
      return this.categoryData[this.selectedCategorySwitcher].data
    },
    transitionDirection () {
      return this.categoryKeys.indexOf(this.prevCategorySwitcher) < this.categoryKeys.indexOf(this.selectedCategorySwitcher) ? 'left' : 'right'
    }
  },
  methods: {
    setOpenCategoryDropdown (categoryKey) {
      if (categoryKey === this.openCategoryDropdown) {
        this.openCategoryDropdown = null
      } else {
        this.openCategoryDropdown = categoryKey
        // Situates the open faq category at the top of the page on mobile
        this.$nextTick(() => {
          const element = document.getElementById(categoryKey)
          const scrollY = element.getBoundingClientRect().top - 80
          window.scrollBy(0, scrollY)
        })
      }
    },
    setSelectedCategorySwitcher (categoryKey) {
      this.prevCategorySwitcher = this.selectedCategorySwitcher
      this.selectedCategorySwitcher = categoryKey
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-detailed {
    background: $secondary;
    padding: 36px 12px;
    @include medium {
        padding: 64px 0;
    }
    .section-header {
        margin-top: 0;
        padding: 0 16px;
    }
}
.faq-dropdowns {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    @include medium {
        display: none;
    }
    .faq-dropdown-button {
        width: 100%;
        border: 1px solid $primary;
        border-radius: $system-border-radius;
        font-family: $headings-font-family;
        font-size: $body-font-size;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        letter-spacing: .2em;
        cursor: pointer;
        background-color: $secondary;
        text-align: left;
        margin: 12px 0;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        &:focus {
            outline: none;
        }
        &.open, &:active {
            background-color: $primary;
            color: $secondary;
            font-weight: $font-weight-bold;
        }
        .button-text {
            width: 100%;
        }
        .icon-container {
            padding: 0 4px;
            .chevron {
                width: 14px;
                height: 8px;
                @include transition-base-all;
                &.up {
                    color: white;
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                }
            }
        }
    }
    .faq-dropdown-content {
        max-height: 0;
        overflow: hidden;
        &.visible {
            max-height: 3000px;
        }
        .faq {
            width: 100%;
            margin: 2rem 0;
        }
    }
}
.faq-switcher {
    display: none;
    @include medium {
        display: block;
    }
    .faq-switcher-categories {
        max-width: 772px;
        width: 100%;
        margin: 40px auto;
        display: flex;
        justify-content: space-between;
        border: 1px solid $primary;
        border-radius: $system-border-radius;
        border-right: none;
        .faq-category {
            margin: 0;
            width: 20%;
            padding: 8px 0;
            font-family: $headings-font-family;
            text-transform: uppercase;
            letter-spacing: .2em;
            font-weight: $font-weight-bold;
            text-decoration: none;
            border: none;
            background-color: $secondary;
            cursor: pointer;
            border-right: 1px solid $primary;
            &:focus {
                outline: none;
            }
        }
        .selected {
            background-color: $primary;
            color: $secondary;
        }
    }
    .faq-switcher-content {
        max-width: 960px;
        width: 90vw;
        margin: 60px auto 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .faq {
            width: 50%;
        }
    }
}
.faq {
    width: 50%;
    text-align: left;
    padding: 0 16px;
    .question {
        font-weight: $font-weight-bold;
        margin-bottom: 0;
    }
    .answer {
        margin-top: 4px;
    }
}
</style>
