import { useRoute } from 'vue-router'

export function useStyleQuizUrl () {
  const route = useRoute()

  function appendReferralParams (url) {
    const referralCode = route.params.referralCode
    if (referralCode) {
      url.searchParams.append('r', referralCode)
    }
    const source = route.query.s
    if (source) {
      url.searchParams.append('s', source)
    }
  }
  function getStyleQuizUrl (referralCampaign) {
    const url = new URL('/signup/intro', window.location.origin)
    const defaultPlanSpecified = referralCampaign && referralCampaign.defaultPlan
    if (defaultPlanSpecified) {
      url.searchParams.append('plan', referralCampaign.defaultPlan.id)
    } else {
      url.searchParams.append('plan', window.armoire.plans.unlimited.id)
    }
    appendReferralParams(url)
    return url.toString()
  }

  return {
    getStyleQuizUrl
  }
}
