<template>
  <component
    :is="type"
    ref="self"
    class="text-link"
    :to="to"
    :class="variant"
    :role="ariaRole"
    :aria-label="label"
    :href="href"
    :target="type === 'a' ? '_blank' : '_self'"
    @click="onClick">
    <slot/>
  </component>
</template>

<script>
export default {
  props: {
    // The underlying component type: button, a, or router-link
    type: {
      type: String,
      default: 'a',
      validator: value => {
        return value.match(/(button|a|router-link|)/)
      }
    },
    ariaRole: {
      type: String,
      default: 'link'
    },
    label: {
      type: String,
      default: null
    },
    // For links, the href
    href: {
      type: String,
      default: null
    },
    // For router-links, the destination
    to: {
      type: Object,
      default: null
    },
    variant: {
      type: String,
      default: 'primary',
      validator: v => v.match(/(primary|secondary)/)
    }
  },
  methods: {
    onClick () {
      if (this.type !== 'router-link') {
        this.$refs.self.blur()
      } else {
        if (this.$refs.self && this.$refs.self.$el) {
          this.$refs.self.$el.blur()
        }
      }
      // Generate an analytics event - 'Clicked Homepage xxx' where xxx is the friendly name/aria label
      if (this.label && window.analytics) {
        window.analytics.track('Clicked Homepage ' + this.label)
      }
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.text-link {
  background-color: inherit;
  color: $orchid;
  font-family: $headings-font-family;
  font-size: $font-size-base * 1.125;
  line-height: $font-size-base * 1.375;
  border: none;
  padding: 4px;
  cursor: pointer;
  outline: none;
  text-align: inherit;
  text-decoration: underline;
  @include transition-base-all;

  &.secondary {
    color: $white;

    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba($white, 0.7);
      box-shadow: 0 0 0 0.2rem rgba($white, 0.7);
    }
  }

  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba($ash, 0.7);
    box-shadow: 0 0 0 0.2rem rgba($ash, 0.7);
  }
}
</style>
