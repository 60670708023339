<template>
  <div class="intro-banner">
    <content-wrapper :max-width="2000">
      <component
        :is="isMobile ? 'content-stack' : 'content-row'"
        :spacing="isMobile ? 0 : 12 ">
        <div class="video-banner">
          <div class="video-wrapper">
            <video
              :poster="getImageUrl(`howitworks/jan2022/video_poster.jpg`)"
              class="video"
              :autoplay="false"
              controls
              muted>
              <source
                src="https://d2wwsm0zoxn9fr.cloudfront.net/videos/HIW_Full_2022.mp4"
                type="video/mp4">
            </video>
          </div>
        </div>
        <homepage-section
          :use-default-padding="false"
          class="banner-section">
          <content-wrapper
            class="banner-content-wrapper"
            :max-width="isMobile ? 320 : 440">
            <content-stack :alignment="isMobile ? 'center' : 'left'">
              <content-stack
                :alignment="isMobile ? 'center' : 'left'"
                :spacing="6">
                <homepage-text
                  tag="h1"
                  class="text-white"
                  :class="isMobile ? 'text-center' : 'text-start'"
                  styling-variant="siteheader">
                  How Armoire Works
                </homepage-text>
                <homepage-text
                  :class="isMobile ? 'text-center' : 'text-start'"
                  styling-variant="copy"
                  class="text-white">
                  Pick the styles you love from your personalized closet. Wear them. Send them back. Repeat!
                </homepage-text>
              </content-stack>
              <get-started-button
                button-variant="secondary"/>
            </content-stack>
          </content-wrapper>
        </homepage-section>
      </component>
    </content-wrapper>
  </div>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import GetStartedButton from '@/homepage/components/common/GetStartedButton.vue'

import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  components: {
    ContentRow,
    ContentStack,
    ContentWrapper,
    HomepageText,
    HomepageSection,
    MarketingButton,
    GetStartedButton
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
.intro-banner {
  margin-top: 80px;
  background: $plum;
}

.video-banner, .banner-section {
  width: 100%;

  @include large {
    flex-shrink: 1;
    width: 48vw;
    height: 29vw;
    max-width: 1000px;
    max-height: 600px;
  }
}

.video-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $origami;
  height: 56vw;
  width: 100vw;

  @include medium {
    width: 50vw;
    height: 29vw;
    min-height: 420px;
    max-height: 600px;
  }
}

.banner-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 36px    ;

  .banner-content-wrapper {
    @include medium {
      margin-left: 8px;
    }
  }

  @include medium {
    min-height: 420px;
  }
}

.video-wrapper {
  max-width: 500px;
  height: 56vw;
  width: 100vw;

  @include medium {
    flex-shrink: 0;
    width: 50vw;
    height: 29vw;
    max-width: 1000px;
    max-height: 600px;
  }
}

.video {
  width: 100%;
  height: auto;
  margin-bottom: -8px;

  @include medium {
    margin-bottom: 0;
  }
}

video[poster]{
  height: 100%;
  object-fit: cover;
  object-position: top;
  width:100%;
}

</style>
