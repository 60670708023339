<template>
  <div
    id="app"
    :class="showOriginal ? 'original-app' : ''">
    <HomepageRedesignExperiment>
      <template #default>
        <homepage-header/>
        <router-view #default="{ Component }">
          <keep-alive>
            <component :is="Component"/>
          </keep-alive>
        </router-view>
        <homepage-footer/>
      </template>
    </HomepageRedesignExperiment>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import HomepageRedesignExperiment from '@shared/components/HomepageRedesignExperiment.vue'
import HomepageFooter from '@/homepage/components/common/HomepageFooter.vue'
import HomepageHeader from '@/homepage/components/common/HomepageHeader.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

/* referral discounts come from Django referral campaign data set via admin */
window.armoire = window.armoire ?? {}
window.armoire.plans = {
  capsule: {
    name: '4-Item Plan',
    buttonName: 'Try 4-Item',
    tagLine: 'The wardrobe pick-me-up',
    id: 'YRmV84VZrv',
    basePrice: '$89',
    basePriceCents: 8900,
    promoPrice: '$89',
    nItems: '4',
    promoDurationMonths: 1,
    signupUrl: '/signup/intro/?plan=YRmV84VZrv',
    mostPopular: false,
    headerImage: 'capsule-plan-header-288w.jpg',
    icon: 'capsule-parcel-icon.png',
    features: [
      'Rent 4 items at a time.',
      'Swap every 30 days.'
    ],
    items: '4 items / month'
  },
  'capsule-7': {
    name: '7-Item Plan',
    buttonName: 'Try 7-Item',
    tagLine: 'The wardrobe enhancer',
    id: '41DNE9XNWv',
    basePrice: '$129',
    basePriceCents: 12900,
    promoPrice: '$99',
    nItems: '7',
    promoDurationMonths: 1,
    signupUrl: '/signup/intro/?plan=41DNE9XNWv',
    mostPopular: false,
    headerImage: '2-item-capsule-plan-header-288w.jpg',
    icon: '7-item-white2x.png',
    features: [
      'Rent 7 items at a time.',
      'Swap every 30 days.'
    ],
    items: '7 items / month'
  },
  unlimited: {
    name: 'Unlimited Plan',
    buttonName: 'Try Unlimited',
    tagLine: 'The wardrobe replacer',
    id: 'XzpND94jx1',
    basePrice: '$249',
    basePriceCents: 24900,
    promoPrice: '$149',
    nItems: '6',
    promoDurationMonths: 1,
    signupUrl: '/signup/intro/?plan=XzpND94jx1',
    mostPopular: true,
    headerImage: 'unlimited-plan-header-288w.jpg',
    icon: 'unlimited-parcel-icon.png',
    features: [
      'Rent 6 items at a time.',
      '<b>Unlimited</b> Swaps.'
    ],
    items: 'Unlimited items'
  }
}

const { showOriginal } = useHomepageRedesignExperiment()

onMounted(() => {
  // hide the loading logo
  const link = document.getElementById('loading-header')
  link.style.display = 'none'
})

defineOptions({
  name: 'HomepageApp'
})
</script>

<style lang="scss">
@import "~@/homepage/assets/styles/global";

.original-app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
