import { useExperimentsStore } from '@shared/stores/experiments.js'
import { toPascalCase } from '@/utils/stringParsing'

export default function useAnalytics () {
  const { getAnalyticsData } = useExperimentsStore()

  async function trackPageView (pageName, data = {}) {
    if (window.analytics) {
      const experimentData = await getAnalyticsData()
      window.analytics.track(`Viewed ${pageName}`, {
        ...experimentData,
        ...data
      })
    }
  }

  async function trackClickEvent (appEntryPoint, label) {
    if (window.analytics) {
      window.analytics.track(`Clicked ${toPascalCase(appEntryPoint)} ${label}`)
    }
  }
  return {
    trackPageView,
    trackClickEvent
  }
}
