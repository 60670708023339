<template>
  <div id="footer">
    <div class="footer-blocks">
      <link-block
        v-for="(block, index) in blocks"
        :key="index"
        :header="block.header"
        :links="block.links"/>
      <div class="app-download">
        <h5 class="appdownload-header">
          Get the App
        </h5>
        <a
          class="appDownloadLink"
          target="_blank"
          :href="appleAppStoreLink">
          <img
            class="appDownloadIcon"
            alt="Download on the App Store"
            :src="appleAppStoreIcon">
        </a>
        <a
          class="appDownloadLink"
          target="_blank"
          :href="googlePlayStoreLink">
          <img
            class="appDownloadIcon"
            alt="Get it on Google Play"
            :src="googlePlayStoreIcon">
        </a>
      </div>
    </div>
    <div
      id="footer-bottom-links"
      class="footer-blocks">
      <link-block
        v-for="(block, index) in bottomBlocks"
        :key="index"
        :footer="block.footer"/>
    </div>
  </div>
</template>

<script>
import LinkBlock from './LinkBlock.vue'

export default {
  name: 'HomepageFooter',
  components: {
    LinkBlock
  },
  data () {
    return {
      appleAppStoreLink: 'https://apps.apple.com/us/app/armoire-style/id1560230748',
      googlePlayStoreLink: 'https://play.google.com/store/apps/details?id=style.armoire.armoiremobile',
      bottomBlocks: [
        {
          footer: [
            {
              text: `© ${new Date().getFullYear()} Armoire Style, Inc.`,
              link: '/'
            }
          ]
        },
        {
          footer: [
            {
              text: 'Legal',
              link: 'https://support.armoire.style/legal',
              openNewTab: true
            }
          ]
        },
        {
          footer: [
            {
              text: 'Rental Policy',
              link: 'https://support.armoire.style/rental-policy',
              openNewTab: true
            }
          ]
        }
      ],
      blocks: [
        {
          header: 'Services',
          links: [
            {
              text: 'Classic',
              link: 'https://armoire.style/signup/intro',
              openNewTab: false
            },
            {
              text: 'Maternity',
              link: 'https://armoire.style/signup/intro',
              openNewTab: false
            },
            {
              text: 'Shop',
              link: 'https://shop.armoire.style/',
              openNewTab: true
            },
            {
              text: 'Gift Cards',
              link: 'https://support.armoire.style/gifts',
              openNewTab: true
            },
            {
              text: 'How it Works',
              link: '/howitworks',
              openNewTab: true
            },
            {
              text: 'FAQ',
              link: 'https://support.armoire.style/faq',
              openNewTab: true
            }
          ]
        },
        {
          header: 'Company',
          links: [
            {
              text: 'About Us',
              link: 'https://support.armoire.style/story-us',
              openNewTab: true
            },
            {
              text: 'Our Team',
              link: 'https://support.armoire.style/about-our-team',
              openNewTab: true
            },
            {
              text: 'Press',
              link: 'https://support.armoire.style/press',
              openNewTab: true
            },
            {
              text: 'Careers',
              link: 'https://support.armoire.style/careers',
              openNewTab: true
            },
            {
              text: 'Contact Us',
              link: 'https://support.armoire.style/contact',
              openNewTab: true
            },
            {
              text: 'Flagship Boutique',
              link: 'https://go.armoire.style',
              openNewTab: true
            }
          ]
        },
        {
          header: 'Social',
          links: [
            {
              text: 'Blog',
              link: 'https://blog.armoire.style/',
              openNewTab: true
            },
            {
              text: 'Instagram',
              link: 'http://instagram.com/armoire.style',
              openNewTab: true
            },
            {
              text: 'TikTok',
              link: 'https://www.tiktok.com/@armoire.style',
              openNewTab: true
            },
            {
              text: 'Facebook',
              link: 'http://www.facebook.com/armoire.style',
              openNewTab: true
            },
            {
              text: 'LinkedIn',
              link: 'https://www.linkedin.com/company/armoire-style/',
              openNewTab: true
            },
            {
              text: 'The Suite',
              link: 'https://thesuite.armoire.style/',
              openNewTab: true
            }
          ]
        }
      ]
    }
  },
  computed: {
    appleAppStoreIcon () {
      return 'https://d2ezpplblfgpjx.cloudfront.net/app-store.png'
    },
    googlePlayStoreIcon () {
      return 'https://d2ezpplblfgpjx.cloudfront.net/google-play.png'
    }
  }
}
</script>

<style lang="scss" scoped>
#footer {
  padding: 15px 20px 15px 20px;
  background-color: $origami;

  @include small {
      padding: 30px 60px 20px 60px;
  }
}

.app-download {
  text-align: left;
  margin-top: 16px;
}

.appdownload-header {
  font-family: $headings-font-family;
  font-weight: $font-weight-semibold;
  font-size: 1rem;
  margin: 1rem 0 1rem;
}

.appDownloadLink {
  margin: 0 12px 12px 0;
  display: inline-block;
}

.appDownloadIcon {
  width: 132px;
  height: auto;
}

.footer-blocks {
  display: flex;
  flex-wrap: wrap;

  @include small {
    flex-wrap: nowrap;
  }
}

#footer-bottom-links {
  margin-top: 30px;

  @include small {
    margin-top: 0px;
  }
}
</style>
