<template>
  <div v-if="submitted">
    <div class="popper">
      🎉
    </div>
    <div class="all-set">
      {{ successHeader }}
    </div>
    {{ successBody }}
  </div>
  <form
    v-else
    class="form"
    @submit.prevent="onSubmit">
    <div class="text-input">
      <input
        id="email-capture-input"
        v-model="email"
        placeholder="Email"
        @input="showError = false">
      <div v-if="showError">
        <label for="email-capture-input">
          Please enter a valid email address.
        </label>
      </div>
    </div>
    <primary-button
      type="button"
      tag="button"
      class="submit"
      @click="onSubmit">
      {{ buttonText }}
    </primary-button>
  </form>
</template>

<script>
import { buildUrl } from '@/homepage/utils/url'
import PrimaryButton from './PrimaryButton'

export default {
  name: 'EmailCapture',
  components: { PrimaryButton },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    successHeader: {
      type: String,
      required: true
    },
    successBody: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      email: '',
      submitted: false,
      showError: false
    }
  },
  methods: {
    onSubmit () {
      if (this.isValidEmail(this.email)) {
        fetch(buildUrl('/api/marketing/subscribe/'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'APP-BUILD-TIME': process.env.VUE_APP_BUILD_TIME
          },
          body: JSON.stringify({ email: this.email, source: this.source })
        })
        this.submitted = true
        if (window.analytics) {
          window.analytics.track('Subscribed to Marketing Emails', {
            source: this.source
          })
        }
        this.$emit('submit')
      } else {
        this.showError = true
      }
    },
    isValidEmail (email) {
      const simpleEmailRegex = /^[^@\s]+@[^@\s.]+\.[^@.\s]+$/g
      return email && email.trim() && email.match(simpleEmailRegex)
    }
  }
}
</script>

<style lang="scss" scoped>
  .form {
    display: flex;
    align-items: flex-start;
    margin-top: 12px !important; // !important to override ContentStack margin.
    max-width: 600px;
    width: 100%;
    flex-wrap: wrap;
    @include medium {
      flex-wrap: nowrap;
    }
  }

  .submit {
    padding: 12px 8px;
    margin-top: 12px;
    margin-left: 0;
    width: 100%;
    @include medium {
      margin-top: 0;
      margin-left: 24px;
      width: 30%;
    }
  }

  .text-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    @include medium {
      width: 70%;
    }
  }

  input {
    border: 1px solid $ash;
    border-radius: 5px;
    padding: 12px;
    font-size: 16px;
    transition: outline 150ms ease;
    outline-color: $plum;
    width: 100%;
  }

  label {
    color: red;
  }

  .popper {
    font-size: 24px;
  }

  .all-set {
    margin-top: 8px;
    font-weight: 600;
  }

</style>
