<template>
  <div>
    <how-it-works-banner/>
    <the-basics v-bind="inViewportProps"/>
    <plans-section v-bind="inViewportProps"/>
    <questions-section v-bind="inViewportProps"/>
    <renting-made-easy
      v-bind="inViewportProps"
      :show-learn-more="false"
      variant="plum"/>
    <stylish-safe
      v-bind="inViewportProps"/>
    <decorative-divider
      class="vertical-divider"
      variant="vertical-dashed"/>
    <comparison-table
      v-bind="inViewportProps"/>
    <membership-perks
      v-bind="inViewportProps"/>
    <members-saying
      v-bind="inViewportProps"/>
    <plans-callout
      v-bind="inViewportProps"
      variant="start-quiz"/>
    <email-capture-homepage
      v-bind="inViewportProps"
      email-source="how-it-works"/>
  </div>
</template>

<script>
import ComparisonTable from './ComparisonTable.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import EmailCaptureHomepage from '@/homepage/components/homepage/EmailCaptureHomepage.vue'
import HowItWorksBanner from './HowItWorksBanner.vue'
import MembershipPerks from './MembershipPerks.vue'
import MembersSaying from './MembersSaying.vue'
import PlansCallout from '@/homepage/components/homepage/PlansCallout.vue'
import PlansSection from '@/homepage/components/common/PlansSection.vue'
import QuestionsSection from './QuestionsSection.vue'
import RentingMadeEasy from '@/homepage/components/homepage/RentingMadeEasy.vue'
import StylishSafe from './StylishSafe.vue'
import TheBasics from './TheBasics.vue'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    ComparisonTable,
    DecorativeDivider,
    EmailCaptureHomepage,
    HowItWorksBanner,
    MembershipPerks,
    MembersSaying,
    PlansSection,
    PlansCallout,
    QuestionsSection,
    RentingMadeEasy,
    StylishSafe,
    TheBasics
  },
  setup () {
    const { trackPageView } = useAnalytics()
    return { trackPageView }
  },
  data () {
    return {
      inViewportProps: {
        'in-viewport-root-margin': '20% 0%',
        'in-viewport-once': true
      }
    }
  },
  activated () {
    this.trackPageView('How It Works Page')
  }
}
</script>

<style lang="scss" scoped>
.vertical-divider {
  // margin-top: 12;
  margin-bottom: -24px;
}
</style>
