<template>
  <component
    :is="type"
    v-bind="componentProps"
    ref="self"
    class="plain-button"
    :role="ariaRole"
    :aria-label="label"
    @click="onClick">
    <slot/>
  </component>
</template>

<script>
export default {
  name: 'PlainButton',
  props: {
    // The underlying component type: button, a, or router-link
    type: {
      type: String,
      default: 'button',
      validator: value => {
        return value.match(/(button|a|router-link|)/)
      }
    },
    tag: {
      type: String,
      default: 'a',
      validator: value => {
        return value.match(/(button|a)/)
      }
    },
    ariaRole: {
      type: String,
      default: 'link'
    },
    label: {
      type: String,
      default: null
    },
    // For links, the href
    href: {
      type: String,
      default: null
    },
    // For router-links, the destination
    to: {
      type: [String, Object],
      default: null
    },
    target: {
      type: String,
      default: null
    }
  },
  computed: {
    componentProps () {
      switch (this.type) {
        case 'a':
          return {
            href: this.href,
            target: this.target ?? '_blank'
          }
        case 'router-link':
          return {
            tag: this.tag,
            to: this.to
          }
        case 'button':
        default:
          return {}
      }
    }
  },
  methods: {
    onClick () {
      if (this.type !== 'router-link') {
        this.$refs.self.blur()
      } else {
        if (this.$refs.self && this.$refs.self.$el) {
          this.$refs.self.$el.blur()
        }
      }
      // Generate an analytics event - 'Clicked Homepage xxx' where xxx is the friendly name/aria label
      if (this.label && window.analytics) {
        window.analytics.track('Clicked Homepage ' + this.label)
      }
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.plain-button {
  background-color: inherit;
  color: inherit;
  border: none;
  padding: 4px;
  cursor: pointer;
  outline: none;
  text-align: inherit;
  text-decoration: none;
  @include transition-base-all;

  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba($ash, 0.7);
    box-shadow: 0 0 0 0.2rem rgba($ash, 0.7);
  }
}
</style>
